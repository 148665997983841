<template>
  <div class="container-fluid py-4">  
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2  mb-4">
            <div
              class="row bg-gradient-info shadow-black border-radius-lg pt-4 pb-3 mb-3"> 
              <h6 class="col text-white text-capitalize ps-3" v-if="view_utms == false">Projects</h6>
              <h6 class="col text-white text-capitalize ps-3" v-if="view_utms">UTMs</h6>
            </div>   
            
            <div v-if="showAlert" :class="['alert', responseStatusCode != 200 ? 'alert-danger': 'alert-success', 'alert-dismissible', 'fade', 'show']" role="alert">
              <span class="text-alert">{{ alertMessage }}</span>
              <button @click="closeAlert()" type="button" class="btn-close d-flex align-items-center px-4" data-bs-dismiss="alert" aria-label="Close">
                <i class="bi bi-x fs-3"></i>
              </button>
            </div>            
            
            <div class="row p-2 col-lg-5 col-md-8 col-sm-12">
              <div class="col" v-if="view_utms == false">
                <label class="mt-3">Project</label>
                <select  class="form-select form-select-lg" v-model="project" @change="selectedProject">
                  <option v-if="projects == null" value="">Charger ... </option>
                  <option @click="select_course(project.project_id)" v-for="project in projects" :value="project.project_id" :key="project.project_id">{{ project.project_name }}</option>
                </select>
              </div>              
            </div>

            <Loading message="Loading" :loading = "loading"/>

            <div v-if="view_utms == false && (courses!=null && Array.isArray(courses) && courses.length > 0) && loading==false" class="card-body px-0 pb-2">              
              <div class="d-flex justify-content-end align-items-center col-md-12 mb-2">
                <!-- Button trigger External API modal -->
                
                <button v-if="webhooks_api.is_webhook" type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#apiModal">
                  <i class="bi bi-gear"></i> &nbsp;API
                </button>
                <button v-else type="button" class="btn btn-secondary text-white" data-bs-toggle="modal" data-bs-target="#apiModal">
                  <i class="bi bi-gear"></i> &nbsp;API
                </button>
              </div>


              <div class="table-responsive p-0">                
                <table class="table table-striped table-hover align-items-center mb-1">
                  <thead class="bg-dark">
                    <tr>
                      <th
                        class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                      >
                      External Course Token
                        
                      </th>
                      <th
                        class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7 ps-2"
                      >
                        Name
                      </th>
                      <th
                        class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7 ps-2"
                      >
                        Students
                      </th>
                      <th
                        class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                      >
                        Active
                      </th>        
                      <th
                        class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                      >
                       Subscriptions
                      </th>       
                      <th
                        class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                      >
                        Options                       
                      </th>                      
                    </tr>
                  </thead>
                  <tbody v-if="loading==false">
                    <tr v-if="courses.length > 0 && Array.isArray(courses)" v-for="course in courses" :key="course.course_external_exercise_id">
                      <td class="text-center">
                        <p class="text-sm font-weight-bold mb-0">{{ course.course_external_exercise_id }}</p>
                      </td>
                      <td class="text-center">
                        <p class="text-sm font-weight-bold mb-0">{{ course.course_name }}</p>
                      </td>
                      <td class="text-center">                        
                        <span class="badge bg-dark" style="font-size: 0.7vmax;">{{ course.count_enrollments }}</span>
                      </td>
                      <td class="text-center">
                        <div class="form-check form-switch d-flex justify-content-center">
                          <input  v-if="course.is_active" value="true" class="form-check-input bg-success" type="checkbox" role="switch" id="flexSwitchCheckActive" checked  @click="changeCourseActivate(course.course_id, course.is_active)" >
                          <input  v-else value="false" class="form-check-input bg-secundary" type="checkbox" role="switch" id="flexSwitchCheckActive"  @click="changeCourseActivate(course.course_id, course.is_active)" >
                          <label v-if="!course.stop_subscriptions" class="form-check-label" for="flexSwitchCheckActive"> &nbsp;&nbsp;Active</label>
                          <label v-else class="form-check-label" for="flexSwitchCheckActive"> &nbsp;&nbsp;Inactive</label>
                        </div>
                      </td>
                      <td class="text-center">
                        <div class="form-check form-switch d-flex justify-content-center">
                          <input  v-if="!course.stop_subscriptions" value="false" class="form-check-input bg-success" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked  @click="stopSubscriptions(course.course_id, course.stop_subscriptions)" >
                          <input  v-else value="true" class="form-check-input bg-secundary" type="checkbox" role="switch" id="flexSwitchCheckChecked"  @click="stopSubscriptions(course.course_id, course.stop_subscriptions)" >
                          <label v-if="!course.stop_subscriptions" class="form-check-label" for="flexSwitchCheckChecked"> &nbsp;&nbsp;Active</label>
                          <label v-else class="form-check-label" for="flexSwitchCheckChecked"> &nbsp;&nbsp;Inactive</label>
                        </div>
                      </td>
                      <td class="text-center">
                       
                        <div v-if="this.auth_utm==true" class="text-center align-middle">
                            <button @click="goToUTMPage(course.course_id, course.course_external_exercise_id)" class="col btn bg-gradient-info my-0">UTMs</button>
                        </div>
                        <div v-else class="text-center align-middle">                        
                          <button class="col btn bg-gradient-secondary my-0 disabled">UTMs</button>
                        </div>
                      </td>
                    </tr>
                    <tr v-else>
                      <td class="text-center" colspan="5">
                        <p class="text-sm text-muted">Courses not found.</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> 
            </div>
            
            <div v-if="view_utms && loading == false">
              <div class="row">
                <div class="d-flex justify-content-between w-100">
                  <div>
                    <button @click="returnToMainPage()" class="btn bg-gradient-info top-button" ><i class="bi bi-arrow-left-circle"></i> &nbsp;&nbsp;Back</button>
                  </div>

                  <div>
                    <button @click="AddUTMForm()" class="btn bg-gradient-info top-button" ><span class="bi bi-plus-circle"> &nbsp;New UTM</span></button>
                  </div>
                </div>
              </div>

              <div class="row">
                <div v-if="show_form" class="utm-form">
                  <div class="d-flex justify-content-center w-100">
                    <div class="card w-50 border">
                      <div class="card-header pt-3 pb-0">
                        <h4 v-if="this.show_add">Create a new UTM</h4>
                        <h4 v-if="this.show_edit">Edit selected UTM</h4>
                      </div>

                      <div class="card-body">                        
                        <div>
                          <label for="name_utm" class="form-label">Control name</label>
                          <div class="input-group input-group-outline">
                            <input v-if="this.show_add" id="name_utm" type="text" class="form-control form-control-default" v-model="utm_identifier" name="name_utm" placeholder="Name">
                            <input v-if="this.show_edit" id="name_utm" type="text" class="form-control form-control-default" v-model="utm_identifier" name="name_utm">
                          </div>
                        </div>
                        
                        <div class="mt-2">
                          <label for="utm" class="form-label">UTM</label>
                          <div class="input-group input-group-outline mt-2">
                            <input v-if="this.show_add" id="utm" type="text" class="form-control form-control-default" v-model="utm_name" name="utm" placeholder="UTM">
                            <input v-if="this.show_edit && registrants_utm <= 0" id="utm" type="text" class="form-control form-control-default" v-model="utm_name" name="utm">
                            <input v-if="this.show_edit && registrants_utm > 0" id="utm" type="text" class="form-control form-control-secondary" style="background-color: #bdc3c750;" v-model="utm_name" name="utm" readonly>
                          </div>
                        </div>
                        
                        <div class="mt-2">
                          <label for="limit_subscriptions" class="form-label">Limit of subscriptions</label>
                          <div class="input-group input-group-outline">
                            <input v-if="this.show_add" id="limit_subscriptions" type="number" class="form-control form-control-default" v-model="limit_registrations" name="limit_subscriptions" placeholder="Limit subscriptions">
                            <input v-if="this.show_edit" id="limit_subscriptions" type="number" class="form-control form-control-default" v-model="limit_registrations" :min="registrants_utm" name="limit_subscriptions">
                          </div>
                        </div>
                        
                        <div class="mt-2">
                          <label for="subscriptions_deadline" class="form-label">Deadline of subscriptions</label>
                          <div class="input-group input-group-outline">
                            <input v-if="this.show_add" id="subscriptions_deadline" type="datetime-local" class="form-control form-control-default" v-model="deadline" name="subscriptions_deadline">
                            <input v-if="this.show_edit" id="subscriptions_deadline" type="datetime-local" class="form-control form-control-default" v-model="deadline" name="subscriptions_deadline">
                          </div>
                        </div>

                        <div class="mx-auto d-flex justify-content-around mt-3">
                          <input v-if="this.show_edit" type="hidden" name="manager_id_utm" id="manager_id_utm" v-model="manager_id_utm">
                          <input v-if="this.show_edit" type="hidden" name="course_id_utms" id="course_id_utms" v-model="course_id_utms">
                          <button @click="cancelUTM()" class="btn mb-0 bg-gradient-danger btn-lg w-40 top-button">Cancel</button>
                          <button v-if="this.show_add" @click="createUTM()" class="btn mb-0 bg-gradient-success btn-lg w-40 top-button">Create</button>
                          <button v-if="this.show_edit" @click="editableUTM()" class="btn mb-0 bg-gradient-success btn-lg w-40 top-button">Edit</button>
                        </div>
                      </div>
                    </div>                    
                  </div>
                </div>
              </div>

              <div class="table-responsive p-0">                
                <table v-if="utms != null && loading == false" class="table table-striped table-hover align-items-center mb-0 mt-4">
                  <thead class="bg-dark">
                    <tr>
                      <th class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7">Name</th>
                      <th class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7">UTM</th>
                      <th class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7">Subscriptions</th>
                      <th class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7">Limit<br>Subscriptions</th>
                      <th class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7">Subscriptions<br>Deadline</th>
                      <th class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7">Actions</th>
                    </tr>
                  </thead>
                  
                  <tbody v-if="loading==false">
                    <tr v-for="utm in this.utms">
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold">
                          {{ utm.manager_name }}
                        </span>
                      </td>
  
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >{{ utm.utm }}</span>
                      </td>
  
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold">
                          {{ utm.registrants }}
                        </span>
                      </td>
  
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold">
                          {{ utm.limit_registrations }}
                        </span>
                      </td>
  
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold">
                          {{ formatDate(utm.registration_deadline) }}
                        </span>
                      </td>
  
                      <td class="align-middle text-center">
                        <div class="d-flex justify-content-center align-items-center gap-3">
                          <button @click="EditUTMForm(utm)" class="btn btn-md bg-gradient-warning my-0">
                            <span class="bi bi-pencil-square"> &nbsp;Edit</span>
                          </button>
  
                          <button @click="accessLinkUTM(utm)" class="btn btn-md bg-gradient-info my-0">
                            <span class="bi bi-link"> &nbsp;Link</span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal External API Configuration -->
  <div v-if="webhooks_api" class="modal fade" id="apiModal" tabindex="-1" aria-labelledby="apiModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="apiModalLabel">External API Configuration</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        
        <div class="modal-body">
          <div>
            <label for="activate_webhook" class="form-label">Is active Webhook?</label>            
            <div class="form-check form-switch mb-3 d-flex algin-items-center gap-2">
              <input v-if="this.webhooks_api.is_active" name="activate_webhook" id="activate_webhook" class="form-check-input px-2 py-1" type="checkbox" role="switch" v-model="this.webhooks_api.is_active" checked>
              <input v-else name="activate_webhook" id="activate_webhook" class="form-check-input px-2 py-1" type="checkbox" v-model="this.webhooks_api.is_active" role="switch">
            </div>
          </div>

          <div class="mb-3">
            <label for="webhook" class="form-label">Webhook</label>
            <input type="text" class="form-control border border-secondary px-2 py-1" id="webhook" placeholder="URL from webhook/endpoint" v-model="this.webhooks_api.webhook_url">
          </div>

          <div class="mb-3">
            <label for="auth_token" class="form-label">Authorization Token</label>
            <input type="text" class="form-control border border-secondary px-2 py-1" id="auth_token" placeholder="Paste here only the token" v-model="webhooks_api.webhook_token" >
          </div>

          <div class="">
            <label for="examplePayload" class="form-label">Example of payload you'll receive</label>
            <textarea class="form-control border border-secondary px-2 py-1" id="examplePayload" rows="8" readonly>Header: {{webhooks_json.HEADER}} 

Body: {{webhooks_json.BODY}}
            </textarea>
            <div class="mt-3">
              <button @click="JsonExampleDownload(this.webhooks_json)" class="btn btn-primary d-flex justify-content-center align-items-center gap-2 m-0 "><i class="bi bi-cloud-arrow-down fs-5 d-flex align-items-end "></i> &nbsp; Json Example</button>    
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-info" @click="editWebhook">Save changes</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.card_upload{
  background-color: DCDCDC;
}

.form-select:focus-visible{
  font-weight: bold;
  border: 1px solid #d2d6da
}

.form-control[type=file]:not(:disabled):not([readonly]) {
  background-color: white;
  border: 1px solid #d2d6da;
  color: black;
}

.form-select {
  background-position: right 18px center
}

.text-alert{
  color: #FFF;
}

.utm-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  gap: 15px;
}

</style>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import {http, http_file, headers} from "../http/index";
import Loading from "@/views/components/Loading.vue";
import { registry } from "chart.js";
import sortBy from "sort-by";


export default {
  name: "ControUsers",

  components : {
    MaterialButton,
    Loading    
  },

  computed :  {     
    camposPreenchidos() {
      if(this.project !=  false && this.course != false){
       return true;
      }
    }
  },
  
  data() {
    return {      
      // Globals
      loading: false, // indicator to display the loading component
      token_user: localStorage.getItem("token"), // takes the user's token from local storage
      
      // Primary view of the project select screen
      projects : null, // will contain the list of projects
      courses: null, // will contain the list of courses
      course: '', // will contain a course object 
      
      // Secondary view (UTMs) after selecting the project
      view_utms: false, // indicates whether the utms are visible
      
      // Visualization of the UTM and creation/editing form
      show_form: false,
      show_add: false,
      show_edit: false,
      course_token_utm: null, // external course token for redirect link

      // Displays request success or failure alerts
      showAlert: false,
      alertMessage: "",
      responseStatusCode: 0,

      utm_identifier: null,
      utm_name: null,
      registrants_utm: null,
      limit_registrations: null,
      deadline: null,

      utms: null,
      course_id_utms: null,
      editUTM: null,      
      manager_id_utm: null,
      webhooks_api: null,
      webhooks_json:{},
      externalHeaders: null,
    };
  },

  mounted() {    
    this.getAllProjects();
  },  

  methods: {
    formatDate(date) {
      const dateObj = new Date(date);
      const day = dateObj.getDate().toString().padStart(2, "0");
      const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
      const year = dateObj.getFullYear();
      const hours = dateObj.getHours().toString().padStart(2, "0");
      const minutes = dateObj.getMinutes().toString().padStart(2, "0");
      const seconds = dateObj.getSeconds().toString().padStart(2, "0");      

      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;      
    },

    formatDateTime(date) {
      const dateObj = new Date(date);
      const day = dateObj.getDate().toString().padStart(2, "0");
      const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
      const year = dateObj.getFullYear();
      const hours = dateObj.getHours().toString().padStart(2, "0");
      const minutes = dateObj.getMinutes().toString().padStart(2, "0");
      const seconds = dateObj.getSeconds().toString().padStart(2, "0");      

      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;      
    },

    getAllProjects(){
      let payload = {
        token: this.token_user
      }
      // headers['Authorization'] = 'Token '+ this.token_user
      http.post('/api/v1/dashboard/list-projects-by-client/', payload)
      .then((response) => (this.projects = response.data.projects))
      .finally(() => {
        this.projects =  this.filteredProjetcs(this.projects)
      });
    },

    filteredProjetcs() {
      const uniqueCompanies = {};
      const result = [];

      if (this.projects) {
        this.projects.forEach(course => {
          const key = course.project_id + '-' + course.project_name;
          if (!uniqueCompanies[key]) {
            result.push(course);
            uniqueCompanies[key] = true;
          }
        });
      }
      return result;
    },

    async select_course(){
      this.loading = true;

      let payload = {
        user_token: this.token_user,
        company_id: this.project
      }
      
      await http.post('/api/v1/client/list-courses/', payload)
      .then((response) => (this.response = response.data))
      .finally(() => {
        setTimeout(() => {
          this.loading = false;
          this.courses = this.response.courses;
          this.auth_utm = this.response.auth_utm;
          this.webhooks_api = this.response.webhooks_api;
          this.externalHeaders = this.response.headers_api;
          
          if (this.auth_utm) {
              this.webhooks_json = {
                "HEADER": this.externalHeaders,
                "BODY": {                   
                  "course_token": "EXTERNAL COURSE TOKEN", 
                  "fields":this.response.fields,
                  "manager":{
                    "manager_id": "string",
                    "utm": "string",
                    "required_utm": "string|bool"
                  }           
                }    
              }  
          }else{
              this.webhooks_json = {
                "HEADER": this.externalHeaders,                
                "BODY": {
                  "course_token": "EXTERNAL COURSE TOKEN", 
                  "fields":this.response.fields,           
                }
            }
          }
        }, 1500);
      });
    },

    selectedProject(){
      this.select_course()
      if(this.project != ''){
      } else{
        this.selectedProj = true;
        this.course = '';
      }
    }, 

    returnToMainPage() {
      this.view_utms = false
      this.show_form = false
      this.showAlert = false
      this.alertMessage = ""
      this.course_token_utm = null
    },

    AddUTMForm() {
      this.show_form = true      
      this.show_add = true
      this.show_edit = false
      this.showAlert = false
      this.alertMessage = ""
    },

    goToUTMPage(course_id=null, course_token=null) {
      this.loading = true

      let payload = {
        user_token: this.token_user,
        course_id: course_id,
      }

      http.post('/api/v1/client/list-manager/', payload)
      .then((response) => (this.utms = response.data.sort(sortBy('manager_id'))))
      .finally(() => {        
        setTimeout(() => {
          this.loading = false;
          this.course_id_utms = course_id;
          this.view_utms = true;
          this.course_token_utm = course_token;          
        }, 1500);
      })
    },    

    EditUTMForm(utm_selected) {
      this.show_form = true      
      this.show_add = false
      this.show_edit = true
      this.showAlert = false
      this.alertMessage = ""
      this.editUTM = utm_selected

      // Preenchendo o formulário com os dados do banco
      this.manager_id_utm = utm_selected.manager_id
      this.utm_identifier = utm_selected.manager_name
      this.utm_name = utm_selected.utm
      this.registrants_utm = utm_selected.registrants
      this.limit_registrations = utm_selected.limit_registrations
      this.deadline = this.formatDateTime(utm_selected.registration_deadline)
    },

    createUTM() {
      let payload = {
        user_token: this.token_user,
        course_id: this.course_id_utms,        
        name: this.utm_identifier,
        utm: this.utm_name,
        registrants: 0,
        limit_registrations: this.limit_registrations,
        registration_deadline: this.deadline,
      }

      http.post('/api/v1/client/create-manager/', payload)
      .then((response) => {
        if (response.status == 200) {
          // Limpa o formulário      
          this.cancelUTM();

          this.showAlert = true;
          this.responseStatusCode = response.status;
          this.alertMessage = "UTM successfully created!";
        }
      })
      .catch((error) => {
        // Limpa o formulário      
        this.cancelUTM();

        this.showAlert = true;        
        this.responseStatusCode = error.status;
        this.alertMessage = "Error creating a UTM.";
      })
      .finally(() => {        
        this.goToUTMPage(this.course_id_utms, this.course_token_utm);
      })
    },

    editableUTM() {
      let count_deadline = this.deadline.length

      if (count_deadline > 16) {
        this.deadline = this.deadline.substring(0, 16)
      }

      if (this.limit_registrations < this.registrants_utm) {
        this.limit_registrations = this.registrants_utm;
      }

      let payload = {
        user_token: this.token_user,
        manager_id: this.manager_id_utm,
        utm: this.utm_name,
        name: this.utm_identifier,
        limit_registrations: this.limit_registrations,
        registration_deadline: this.deadline,
      }

      http.post('/api/v1/client/edit-manager/', payload)
      .then((response) => {
        if (response.status == 200) {
          // Limpa o formulário      
          this.cancelUTM();

          this.showAlert = true;
          this.responseStatusCode = response.status;
          this.alertMessage = "UTM successfully edited!";
        }
      })
      .catch((error) => {
        // Limpa o formulário      
        this.cancelUTM();

        this.showAlert = true;        
        this.responseStatusCode = error.status;
        this.alertMessage = "UTM editing error.";
      })
      .finally(() => {        
        this.goToUTMPage(this.course_id_utms, this.course_token_utm);
      })
    },

    cancelUTM() {
      // Limpa o formulário      
      this.utm_identifier = null
      this.utm_name = null
      this.limit_registrations = null
      this.deadline = null
      this.registrants_utm = null

      // Para de exibir o formulário
      this.show_form = false
      this.show_add = true
    },

    closeAlert() {
      this.showAlert = false
      this.alertMessage = ""
    },

    accessLinkUTM(utm_selected) {
      // TO DO colocar a variável de ambiente no lugar do link fixo
      if (process.env.VUE_APP_ENVRION_VAR == 'production') {
        window.open(process.env.VUE_APP_URL_FROM_APP_USER_PROD+this.course_token_utm+'/utm/'+utm_selected.utm, '_blank');
      } else if (process.env.VUE_APP_ENVRION_VAR == 'development') {        
        window.open(process.env.VUE_APP_URL_FROM_APP_USER_DEV+this.course_token_utm+'/utm/'+utm_selected.utm, '_blank');
      } else if (process.env.VUE_APP_ENVRION_VAR == 'lab'){
        window.open(process.env.VUE_APP_URL_FROM_APP_USER_LAB+this.course_token_utm+'/utm/'+utm_selected.utm, '_blank');
      }
    },

    JsonExampleDownload(fields) {

      const json = JSON.stringify(fields.BODY, null, 2);
      const blob = new Blob([json], { type: 'application/json' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'exemplo.json';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      this.showAlert = true
      this.alertMessage = "The example Json file has been downloaded."
      this.responseStatusCode = 200
    },

    editWebhook() {
      let payload = {
        user_token: this.token_user,
        company_id: this.project,
        webhook_url: this.webhooks_api.webhook_url,
        webhook_token: this.webhooks_api.webhook_token,
        is_active: this.webhooks_api.is_active
      }
      
      http.post('/api/v1/client/edit_webhook/', payload)
      .then((response) => {
        if (response.status == 200) {
          this.showAlert = true
          this.alertMessage = "The webhook has been edited."
          this.responseStatusCode = 200
        }
      })
      .catch((error) => {
        this.showAlert = true
        this.alertMessage = "Error editing the webhook."
        this.responseStatusCode = error.status
      })
      .finally(() => {
        $('#apiModal').modal('hide')
      })

    },

    stopSubscriptions(course_id, stop_subscriptions) {
      let payload = {
        course_id: course_id,
        stop_subscriptions: !stop_subscriptions
      }

      http.post('/api/v1/client/stop_subscriptions/', payload)
      .then((response) => {
        if (response.status == 200) {
          this.showAlert = true
          if (stop_subscriptions) {
            this.alertMessage = "The subscriptions have been started."
          } else {
            this.alertMessage = "The subscriptions have been stopped."
          }
          this.responseStatusCode = 200
        }
      })
      .catch((error) => {
        this.showAlert = true
        this.alertMessage = "Error stopping subscriptions."
        this.responseStatusCode = error.status
      })
      .finally(() => {
        this.select_course()
      })
    },
    changeCourseActivate(course_id, is_active) {
      let payload = {
        course_id: course_id,
        is_active: !is_active
      }

      http.post('/api/v1/client/change_course_activate/', payload)
      .then((response) => {
        if (response.status == 200) {
          this.showAlert = true
          if (!is_active) {
            this.alertMessage = "The course have been active."
          } else {
            this.alertMessage = "The course have been inactive."
          }
          this.responseStatusCode = 200
        }
      })
      .catch((error) => {
        this.showAlert = true
        this.alertMessage = "Error stopping subscriptions."
        this.responseStatusCode = error.status
      })
      .finally(() => {
        this.select_course()
      })
    },
  }
}
</script>