<template>
  <div class="container-fluid p-1 position-relative ">
    <div class="row">
      <div class="d-flex justify-content-between w-100">
        <div class="px-1">
          <router-link
            :to="{ name: 'List Push Batch By User', params: { id: project_id } }"
            class="btn bg-gradient-info top-button"
          >
            <i class="bi bi-arrow-left-circle"></i> &nbsp;&nbsp;Back
          </router-link>
        </div>
      </div>
    </div>
  </div>
  
  <div class="row">
    <div class="col-12">
      <div class="card my-4">
        <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2  mb-4">
          <div
            class="row bg-gradient-info shadow-black border-radius-lg pt-4 pb-3 mb-3"> 
            <h6 class="col text-white text-capitalize ps-3">Participants - Push Batch Details</h6>
          </div>

          <Loading message="Loading datas" :loading = "loading"/>

          <div v-if="this.loading == false">
            <table class="table table-striped table-hover align-items-center mb-0 mt-4">
              <thead class="bg-dark">
                <tr>
                  <th
                    class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                  >
                  Number
                  </th>
                  <th
                    class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                  >
                  Status
                  </th>
                  <th
                    class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                  >
                  Message
                  </th>
                  <th
                    class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                  >
                  Created
                  </th>
                  <th
                    class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                  >
                  Modified
                  </th>
                </tr>
              </thead>
              
              <tbody>
                <tr v-if="this.haveDetails == true" v-for="pushLine in pushLines" :key="pushLine.id">
                  <td class="align-middle text-center">
                    <div class="d-flex justify-content-center">
                      <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-0 text-sm">{{pushLine.number}}</h6>
                      </div>
                    </div>
                  </td>
                  <td class="align-middle text-center text-sm">
                    <span v-if="pushLine.status == 'INITIAL'" class="badge badge-sm bg-gradient-info">
                      {{pushLine.status}}
                    </span>
                    <span v-if="pushLine.status == 'RUNNING'" class="badge badge-sm bg-gradient-primary">
                      {{pushLine.status}}
                    </span>
                    <span v-if="pushLine.status == 'FINISHED'" class="badge badge-sm bg-gradient-success">
                      {{pushLine.status}}
                    </span>
                    <span v-if="pushLine.status == 'FAILED'" class="badge badge-sm bg-gradient-danger">
                      {{pushLine.status}}
                    </span>
                    <span v-if="pushLine.status == 'REGISTERING'" class="badge badge-sm bg-gradient-info">
                      {{pushLine.status}}
                    </span>
                    <span v-if="pushLine.status == 'WAITING'" class="badge badge-sm bg-gradient-warning">
                      {{pushLine.status}}
                    </span>
                  </td>
                  <td class="align-middle text-center">
                    <span class="text-secondary text-xs font-weight-bold"
                      >{{pushLine.message}}</span
                    >
                  </td>
                  <td class="align-middle text-center">
                    <span class="text-secondary text-xs font-weight-bold"
                      >{{formatDate(pushLine.created)}}</span
                    >
                  </td>
                  <td class="align-middle text-center">
                    <span class="text-secondary text-xs font-weight-bold"
                      >{{formatDate(pushLine.modified)}}</span
                    >
                  </td>
                </tr>

                <tr v-else>
                  <td colspan="4" class="align-middle text-center text-sm text-secondary">
                    No data available
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from "../http/index";
import lysLogo from "@/assets/img/icon-lys.png";
import MaterialButton from "@/components/MaterialButton.vue";
import Loading from "@/views/components/Loading.vue";
import MiniStatisticsCard from "./components/MiniStatisticsCard.vue";


export default {
  name: "ControUsers",

  components : {
    MaterialButton,
    MiniStatisticsCard,
    Loading    
  },

  data() {
    return {
      loading: true,
      lysLogo,
      pushBatchId: this.$route.params.id,
      project_id: this.$route.params.course_id,      
      token_user: localStorage.getItem("token"),
      pushLines: null,
    };
  },

  mounted() {
    this.pushBathDetails(this.$route.params.id)
  },  

  methods: {
    formatDate(date, time = true) {
      const dateObj = new Date(date);
      const day = dateObj.getDate().toString().padStart(2, "0");
      const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
      const year = dateObj.getFullYear();
      const hours = dateObj.getHours().toString().padStart(2, "0");
      const minutes = dateObj.getMinutes().toString().padStart(2, "0");
      const seconds = dateObj.getSeconds().toString().padStart(2, "0");
      
      if (!time) {
        return `${year}\-${month}\-${day}`;
      }else{
        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
      }      
    },

    pushBathDetails() {
      let payload = {
        push_batch_id: this.$route.params.id
      }

      http.post('/api/v1/dashboard/list-push-line-by-batch/', payload)
      .then((response) => {
        this.pushLines = response.data
        this.haveDetails = true;
      })
      .catch((error) => {
      })
      .finally(() => {
        this.loading = false
      }) 
    },
  }
}
</script>

<style scoped>
</style>