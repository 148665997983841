<template>
    <div class="table-responsive align-items-center mb-0 mt-3" style = "margin: 0px 20px;">
        <table class="table table-striped table-hover align-items-center">
            <thead class="bg-dark">
                <tr>
                    <th class="text-uppercase text-white text-sm font-weight-bolder opacity-7 px-2 mx-0">Name</th>
                    <th class="text-uppercase text-white text-sm font-weight-bolder opacity-7 px-2 mx-0">E-mail</th>
                    <th class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7">First User</th>
                    <th class="col-5 text-center text-uppercase text-white text-sm font-weight-bolder opacity-7">Manager Actions</th>
                </tr>
            </thead>

            <tbody>
                <tr v-if="data_users.users != null && data_users.users!=0" v-for="user in data_users.users" :key="user.id">
                    <td><span class="file"> {{ user.first_name }} {{ user.last_name }}</span></td>

                    <td>{{ user.email }}</td>

                    <td class="text-center">
                        <span v-if="user.is_firs_user_client === true" class="text-xs font-weight-bold" style="color: rgb(58, 57, 57)"  >
                        <i class="bi bi-check-circle text-success" style="font-size:2rem;"></i>
                        </span>
                        <span v-else class="text-xs font-weight-bold" style="color: rgb(58, 57, 57)"  >
                        <i class="bi bi-x-circle text-danger" style="font-size:2rem;"></i>
                        </span>
                    </td>

                    <td class="d-flex align-items-center justify-content-center gap-2" v-if="this.login_user_email == user.email || this.first_user === true">
                        <MaterialButton color="warning" v-on:click="set_values_user(user.name, user.sobrenome, user.email)" class="m-1 col" data-bs-toggle="modal" data-bs-target="#altername" >Change name</MaterialButton>

                        <MaterialButton color="warning" v-on:click="set_values_password(user.email)" class="m-1 col"  data-bs-toggle="modal" data-bs-target="#alterpassword">Change password</MaterialButton>

                        <MaterialButton v-if="user.is_report_enabled === true" color="warning" v-on:click="update_reports_user(user.email, false)" class="m-1 col">Disable reports</MaterialButton>

                        <MaterialButton v-else color="warning" v-on:click="update_reports_user(user.email, true)" class="m-1 col">Enable reports</MaterialButton>
                    </td>
                    <td v-else></td>
                </tr>
                <tr v-else>
                    <td colspan="4" class="text-center text-muted text-sm">No users found.</td>
                </tr>
            </tbody>
        </table>
    </div>    

    <!-- modal name -->
    <div class="modal fade" id="altername" tabindex="-1" aria-labelledby="createnewuserLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="createnewuserlLabel">Change Name &nbsp;<span class="badge bg-info fs-8 text-lowercase">{{this.email_selected}}</span></h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <div class="card bg-light border" id="card-newuser">
                        <div class="card-body py-2 px-2">                        
                            <div class="d-flex flex-column gap-2">
                                <label for="floatingEditName" class="form-label py-0 my-0 text-dark font-weight-bold">Name</label>
                                <div class="input-group input-group-outline">                                
                                    <input type="text" v-model="this.new_first_name" class="form-control form-control-default" id="floatingEditName" placeholder="First name">
                                </div>
                            </div>

                            <div class="mt-3 d-flex flex-column gap-2">
                                <label for="floatingEditSurname" class="form-label py-0 my-0 text-dark font-weight-bold">Surname</label>
                                <div class="input-group input-group-outline">                                
                                    <input type="text" v-model="this.new_last_name" class="form-control form-control-default" id="floatingEditSurname" placeholder="Surname">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-success" @click="update_name_user" >Save</button>
                </div>
            </div>
        </div>
    </div>

    <!--update password -->
    <div class="modal fade" id="alterpassword" tabindex="-1" aria-labelledby="createnewuserLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="createnewuserlLabel">Change Password &nbsp;<span class="badge bg-info fs-8 text-lowercase">{{this.email_selected}}</span></h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <div class="card bg-light border" id="card-newuser">
                        <div class="card-body py-2 px-2">                            
                            <div class="d-flex flex-column gap-2">
                                <label for="floatingOldPass" class="form-label py-0 my-0 text-dark font-weight-bold">Old Password</label>
                                <div class="input-group input-group-outline">                                    
                                    <input type="password" v-model="this.old_password" class="form-control form-control-default" id="floatingOldPass" placeholder="name@example.com">
                                </div>
                            </div>
    
                            <div class="mt-3 d-flex flex-column gap-2">
                                <label for="floatingEditPassword" class="form-label py-0 my-0 text-dark font-weight-bold">New Password</label>
                                <div class="input-group input-group-outline">                                    
                                    <input type="password" v-model="this.new_password" class="form-control form-control-default" id="floatingEditPassword" placeholder="Password">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-success" @click="update_password_user" >Save</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {http} from "@/http/index";
    import MaterialButton from "@/components/MaterialButton.vue";

    export default {
        name: "UserTable",

        components : { 
            MaterialButton
        },

        data() {
            return {
                actualy_user_token: null,
                login_user_email: null,
                first_user: null,
                email_selected: null,
                is_report_selected: null,
                first_name: null,
                last_name: null,
                new_first_name: null,
                new_last_name: null,
                old_password: null,
                new_password: null,
                data_users: [],
                loading: true
            };
        },
    
        beforeMount() {
            this.actualy_user_token = localStorage.getItem("token")
            this.login_user_email = localStorage.getItem("email")
            this.first_user = localStorage.getItem("first_user") == 'true' ? true : false
        },        

        methods : {
            update_name_user: function() {
                this.loading = true;

                var payload={
                    token: localStorage.getItem("token"),    
                    new_first_name: this.new_first_name,
                    new_last_name: this.new_last_name,  
                    email: this.email_selected                        
                }

                http.post('/api/v1/dashboard/alter-name/', payload)                
                .then((response) => {
                    this.loading = false;
                    window.location.reload();
                })
                .catch(error => {
                    console.log(error.message)
                })
            },

            update_password_user: function() {
                this.loading = true;

                var payload={
                    token: this.actualy_user_token,
                    old_password: this.old_password,
                    new_password: this.new_password,
                    email: this.email_selected
                }

                http.post('/api/v1/dashboard/alter-password/', payload)
                .then((response) => {
                    this.loading = false;
                    window.location.reload();
                })
                .catch(error => {
                    console.log(error.message)
                })
            },

            set_values_user: function (selected_name, selected_sobrenome, email_selected) {
                this.name = selected_name;
                this.email_selected = email_selected;
                this.sobrenome = selected_sobrenome;
                },

            set_values_password: function (email_selected) {
                this.email_selected = email_selected;    
            },

            update_reports_user: function (email_user, is_report_user) {
                this.loading = true;

                var payload = {
                    token: this.actualy_user_token.toString(),
                    email: email_user.toString(),
                    is_report: is_report_user == 'true' || is_report_user === true ? true : false
                }

                http.post('/api/v1/dashboard/change_is_report/', payload)                
                .then((response) => {
                    this.loading = false;
                    window.location.reload();
                })
                .catch(error => {
                    console.log(error.message)
                })
            }
        },

        props: {
            data_users: {
                type: Array,
                required: true
            }
        }
    }
</script>

<style scoped>
    .file{
        font-weight: 800;
        color: #616161
    }
</style>