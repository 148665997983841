<template>
  <div class="container-fluid p-1 position-relative ">
    <div class="row">
      <div class="d-flex justify-content-between w-100">
        <div class="px-1">
          <router-link
            :to="{ name: 'BatchParticipantsID', params: { id: project_id } }"
            class="btn bg-gradient-info top-button"
          >
            <i class="bi bi-arrow-left-circle"></i> &nbsp;&nbsp;Back
          </router-link>
        </div>
      </div>
    </div>
  </div>
  
  <div class="row">
    <div class="col-12">
      <div class="card my-4">
        <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2  mb-4">
          <div
            class="row bg-gradient-info shadow-black border-radius-lg pt-4 pb-3 mb-3"> 
            <h6 class="col text-white text-capitalize ps-3">Participants - List Registration Imports</h6>
          </div>

          <div v-if="this.showAlert"
            :class="[
              'alert',
              this.responseStatusCode == 'success'
                ? 'alert-success'
                : this.responseStatusCode == 'error'
                ? 'alert-danger'
                : this.responseStatusCode == 'pending'
                ? 'alert-warning'
                : 'alert-info',
            ]"
            role="alert"
          >
            <span class="text-alert" v-html="alertMessage"></span>
            <div v-if="this.alertButton === true" class="mt-3">
              <button class="btn btn-secondary btn-md" @click="downloadLog">Download Details</button>
            </div>
          </div>

          <Loading message="Processing your import" :loading = "loadingImport"/>

          <div v-if="this.loadingImport === false" class="col-lg-20 mb-md-0 mb-4">
            <div class="row">
              <div class="d-flex justify-content-end w-100">
                <div class="d-flex gap-2">
                  <button @click="CSVExampleDownload()" class="btn bg-gradient-info top-button" ><span class="bi bi-download"> Example</span></button>
                  <button class="btn bg-gradient-info top-button" data-bs-toggle="modal" data-bs-target="#showPushBatchForm"><span class="bi bi-cloud-arrow-up"> Import</span></button>
                </div>
              </div>
            </div>

            <table class="table table-striped table-hover align-items-center mb-0 mt-4">
              <thead class="bg-dark">
                <tr>
                  <th
                    class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                  >
                  Identifier
                  </th>
                  <th
                    class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                  >
                  Created by
                  </th>
                  <th
                    class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                  >
                  Status
                  </th>
                  <th
                    class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                  >
                  Numbers Count
                  </th>
                  <th
                    class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                  >
                  Processed Numbers
                  </th>
                  <th
                    class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                  >
                  Created
                  </th>
                  <th
                    class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                  >
                  Modified
                  </th>
                  <th
                    class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                  >
                  Details
                  </th>
                </tr>
              </thead>
              
              <tbody v-if="pushBatches!=null && pushBatches!=0">
                <tr v-if="loading==false" v-for="pushBatch in pushBatches" :key="pushBatch.id">
                  <td >
                    <div class="d-flex px-2 py-1">
                      <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-0 text-sm">{{pushBatch.identifier}}</h6>
                      </div>
                    </div>
                  </td>
                  <td class="align-middle text-center">
                    <span class="text-secondary text-xs font-weight-bold"
                      >{{pushBatch.created_by_email}}</span
                    >
                  </td>
                  <td class="align-middle text-center text-sm">
                    <span v-if="pushBatch.status == 'INITIAL'" class="badge badge-sm bg-gradient-success">
                      Registered
                    </span>
                    <span v-if="pushBatch.status == 'RUNNING'" class="badge badge-sm bg-gradient-primary">
                      {{pushBatch.status}}
                    </span>
                    <span v-if="pushBatch.status == 'FINISHED'" class="badge badge-sm bg-gradient-success">
                      {{pushBatch.status}}
                    </span>
                    <span v-if="pushBatch.status == 'FAILED'" class="badge badge-sm bg-gradient-danger">
                      {{pushBatch.status}}
                    </span>
                    <span v-if="pushBatch.status == 'REGISTERING'" class="badge badge-sm bg-gradient-info">
                      {{pushBatch.status}}
                    </span>
                    <span v-if="pushBatch.status == 'WAITING'" class="badge badge-sm bg-gradient-warning">
                      {{pushBatch.status}}
                    </span>
                  </td>
                  <td class="align-middle text-center">
                    <span class="text-secondary text-xs font-weight-bold"
                      >{{pushBatch.numbers_count}}</span
                    >
                  </td>
                  <td class="align-middle text-center">
                    <span class="text-secondary text-xs font-weight-bold"
                      >{{pushBatch.processed_numbers}}</span
                    >
                  </td>
                  <td class="align-middle text-center">
                    <span class="text-secondary text-xs font-weight-bold"
                      >{{formatDate(pushBatch.created)}}</span
                    >
                  </td>
                  <td class="align-middle text-center">
                    <span class="text-secondary text-xs font-weight-bold"
                      >{{formatDate(pushBatch.modified)}}</span
                    >
                  </td>
                  <td class="align-middle text-center">
                    <router-link 
                      title="Batch details"
                      class="btn bg-gradient-warning btn-rounded my-0 fs-6 m-1"
                      :to="{ name: 'Push Batch Details', params: { id: pushBatch.id, course_id: this.project_id } }"
                    >
                      <i class="bi bi-plus-circle"></i>
                    </router-link>
                  </td>
                </tr>

                <tr v-else>
                  <td colspan="8" class="text-center text-secondary">
                    <Loading message="Loading datas" :loading = "loading"/>
                  </td>
                </tr>
              </tbody>

              <tbody v-else>
                <tr v-if="loading==false">
                  <td colspan="8" class="text-center text-secondary">No registration imports found.</td>
                </tr>
                <tr v-else>
                  <td colspan="8" class="text-center text-secondary">
                    <Loading message="Loading datas" :loading = "loading"/>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Import Participants in Batch -->
  <div class="modal fade" id="showPushBatchForm" tabindex="-1" aria-labelledby="showPushBatchFormLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="showPushBatchFormLabel">Import Participants in Batch</h5>
        </div>
        
        <div class="modal-body">
          <div v-if="this.showPushBatchAlert === true" class="d-flex flex-column align-items-center justify-content-center gap-3">
            <div class="alert alert-danger d-flex align-items-center justify-content-center flex-column m-4" role="alert">
              <i class="fs-1 text-alert m-3 bi bi-exclamation-triangle"></i>
              <div class="text-alert fs-5">
                <ol>
                  <li>
                    <b class="fw-bold" style="text-transform: uppercase;">Download the Example File</b><br>
                    Click on the 'EXAMPLE' button at the top of this page to download the sample CSV file. Use this file as a template for importing participants.
                  </li>
                  <li>
                    <b class="fw-bold" style="text-transform: uppercase;">Fill in the Data Without Editing Column Names</b><br>
                    Ensure you fill in the required data but do not modify the column names in the file.
                  </li>
                  <li>
                    <b class="fw-bold" style="text-transform: uppercase;">Verify the Participant Numbers</b><br>
                    The column containing the participant number will be used to register participants in the course. Make sure the number is accurate and in the correct format.
                  </li>
                  <li>
                    <b class="fw-bold" style="text-transform: uppercase;">Pay Attention to the Phone Number Format</b><br>
                    <ul>
                      <li>Ensure the correct number of digits.</li>
                      <li>Include the country code (e.g., +521 for Mexico).</li>
                      <li>Do not use spaces or special characters (only the + symbol at the beginning is allowed).</li>
                    </ul>
                  </li>
                  <li>
                    <b class="fw-bold" style="text-transform: uppercase;">Import the File</b><br>
                    Select the file and click on the 'IMPORT' button.
                  </li>
                  <li>
                    <b class="fw-bold" style="text-transform: uppercase;">Important Notes on File Line Limits</b><br>
                    <ul>
                      <li>For registration-only imports, there is no limit on the number of lines.</li>
                      <li>For push requests, the file must be limited to blocks of 6,000 numbers. If your file exceeds this limit, split it into smaller files before importing.</li>
                    </ul>
                  </li>
                </ol>
              </div>
            </div>            

            <button class="btn btn-info" @click="dismissPushBatchAlert">I read and understood</button>
          </div>

          <div v-else>
            <div>
              <label for="identifier" class="form-label">Identifier</label>
              <div class="input-group input-group-outline">
                <input id="identifier" type="text" class="form-control form-control-default" name="identifier" placeholder="Identifier">
              </div>
            </div>

            <div class="mt-2">
              <label for="ddichecker" class="form-label">What is the DDI of the phones in the CSV?</label>
              <select class="form-select px-2" id="ddichecker" aria-label="Select a DDI">
                <option value="" selected>Select a DDI</option>
                <option v-for="ddi in listOfDDIs" :value="ddi.ddi" :key="ddi">{{ ddi.ddi + " - " + ddi.country  }}</option>
              </select>              
            </div>

            <div class="mt-2">
              <label for="file" class="form-label">CSV File</label>
              <div class="input-group input-group-outline">
                <input id="file" type="file" class="form-control" name="file" accept=".csv" placeholder="File">
              </div>
            </div>

            <div style="position: relative; margin-bottom: 10px; margin-top: 10px;">   
              Request push sending:
              <input style="position: absolute; margin-top: 7px; margin-left: 2px;" type="checkbox" id="checkbox" v-model="checked"/>
            </div>

            <div class="mx-auto d-flex justify-content-around mt-3">                          
              <button data-bs-dismiss="modal" @click="cancelPushBatch()" class="btn mb-0 bg-gradient-danger btn-lg w-40 top-button">Cancel</button>
              <button data-bs-dismiss="modal" @click="importCSVFile()" class="btn mb-0 bg-gradient-success btn-lg w-40 top-button" :disabled="this.isImporting">Import</button>
            </div>
          </div>
        </div>              
      </div>
    </div>
  </div>
</template>

<script>
import { http, http_file } from "../http/index";
import lysLogo from "@/assets/img/icon-lys.png";
import MaterialButton from "@/components/MaterialButton.vue";
import Loading from "@/views/components/Loading.vue";
import MiniStatisticsCard from "./components/MiniStatisticsCard.vue";
import jschardet from 'jschardet';


export default {
  name: "ControUsers",

  components : {
    MaterialButton,
    MiniStatisticsCard,
    Loading    
  },

  data() {
    return {
      loading: true,
      loadingImport: false,
      lysLogo,
      project_id: this.$route.params.id,      
      token_user: localStorage.getItem("token"),

      pushBatchIdentifier: "",
      selectedDDI: "",
      pushBatchFile: "",
      checked: false,
      alertButton: false,
      logMessage: "",
      showAlert: false,
      alertMessage: "",
      responseStatusCode: "",
      pushBatches : null,

      showPushBatchAlert: true,
      validateImportSuccess: true,
      listFields: null,
      listOfDDIs: [],

      isImporting: false,
    };
  },

  mounted() {
    this.getAllPushBatches();
    this.listFieldsByCourse();
    this.listAllDDIs();
  },  

  methods: {
    formatDate(date, time = true) {
      const dateObj = new Date(date);
      const day = dateObj.getDate().toString().padStart(2, "0");
      const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
      const year = dateObj.getFullYear();
      const hours = dateObj.getHours().toString().padStart(2, "0");
      const minutes = dateObj.getMinutes().toString().padStart(2, "0");
      const seconds = dateObj.getSeconds().toString().padStart(2, "0");
      
      if (!time) {
        return `${year}\-${month}\-${day}`;
      }else{
        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
      }      
    },

    async listAllDDIs() {
      await http.post('/api/v1/get-all-ddis/')
      .then((response) => {
        this.listOfDDIs = response.data.filter(ddi => ddi.country !== "Global");
      })
      .catch((error) => {        
        this.showAlert = true
        this.alertMessage = "An error occurred while listing DDIs. Please try again.<br>"
        this.responseStatusCode = "error"
      })
    },

    async importCSVFile() {
      this.showAlert = false;
      this.logMessage = "";
      this.alertMessage = "";
      this.responseStatusCode = "";
      this.loadingImport = true;
      this.validateImportSuccess = true;

      var validateForm = await this.validateFormFields();
      
      if (validateForm === false) {
        this.loadingImport = false;
        this.showAlert = true;
        this.pushBatchFile = "";
        document.getElementById('file').value = "";
        return;
      } else {
        this.pushBatchIdentifier = document.getElementById('identifier').value
        this.pushBatchFile = document.getElementById('file').files[0]
        this.selectedDDI = document.getElementById('ddichecker').value
        
        var validateFileType = await this.validateImportFileType(this.pushBatchFile);

        if (validateFileType === false) {
          this.loadingImport = false;
          this.showAlert = true;
          this.pushBatchFile = "";
          document.getElementById('file').value = "";
          return;
        } else {
          var validateFile = await this.validateFileCSV(this.pushBatchFile);

          if (validateFile === false) {
            this.loadingImport = false;
            this.alertButton = true;
            this.showAlert = true;
            this.pushBatchFile = "";
            document.getElementById('file').value = "";
            return;
          } else {
            this.loadingImport = false;
            this.alertButton = false;
            this.showAlert = false;            
            this.alertMessage = '';

            var newFile = await this.rewriteCSV(this.pushBatchFile);

            let formData = new FormData()
            formData.append('identifier', this.pushBatchIdentifier)
            formData.append('file', newFile, 'cleaned_file.csv')
            formData.append('course_id', this.project_id)
            formData.append('token', this.token_user)
            formData.append('email_for_push', this.checked)

            this.createPushBatch(formData);
          }
        }
      }
    },

    validateFormFields() {
      if (document.getElementById('identifier').value === '' || document.getElementById('ddichecker').value === '' || document.getElementById('file').files.length === 0) {
        this.showAlert = true;
        this.alertMessage = "Please fill in all fields.";
        
        this.responseStatusCode = "pending";
        this.validateImportSuccess = false;
        return this.validateImportSuccess;
      }
    },

    validateImportFileType(file) {
      if (file.type !== 'text/csv') {
        this.showAlert = true
        this.alertMessage = "The file selected is not a CSV file."
        this.pushBatchFile = ""
        document.getElementById('file').value = ""

        this.responseStatusCode = "pending";
        this.validateImportSuccess = false;
        return this.validateImportSuccess;
      }
    },

    async validateFileCSV(file) {
      // Validate the file encoding
      var fileEncoding = await this.detectFileEncoding(file);
      if (fileEncoding.toLowerCase() !== 'utf-8' && fileEncoding.toLowerCase() !== 'ascii' && fileEncoding.toLowerCase() !== 'ansi' && fileEncoding.toLowerCase() !== 'koi8-r') {
        this.alertMessage += "The file selected is not a valid UTF-8 or ASCII or ANSI CSV file.<br>";
        this.responseStatusCode = "pending";
        this.validateImportSuccess = false;

        this.logMessage += "ENCODING ERROR:\nThe file selected is not a valid UTF-8 or ASCII or ANSI CSV file.\n\n";
      }
      
      // Validate the file columns titles
      var validateTitles = await this.checkFileColumnsTitles(file);

      // Validate the file phone numbers
      var validatePhones = await this.checkFilePhoneNumbers(file);
      
      // Validate the file rows data
      var validateRowDatas = await this.checkCSVRowsDatas(file);

      if (validateTitles === true && validatePhones === true && validateRowDatas === true) {
        this.validateImportSuccess = true;
      } else {
        this.validateImportSuccess = false;
      }
      
      return this.validateImportSuccess;
    },

    detectFileEncoding(file) {
      return new Promise((resolve, reject) => {
        let readerDetect = new FileReader()

        readerDetect.onload = (event) => {
          let arrayBuffer = event.target.result;
          let uint8Array = new Uint8Array(arrayBuffer);
          let binaryString = Array.from(uint8Array)
            .map(byte => String.fromCharCode(byte))
            .join('');
          let detection = jschardet.detect(binaryString, { minimumThreshold: 0 });

          if (detection && detection.encoding) {
            resolve(detection.encoding);
          } else {
            reject(new Error("The file's encoding could not be detected."));
          }
        };

        readerDetect.onerror = () => {
          reject(new Error('Error when reading the file.'));
        };

        readerDetect.readAsArrayBuffer(file);
      });
    },

    async rewriteCSV(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = async (event) => {
          const csvContent = event.target.result;
          const normalizedContent = csvContent.replace(/\r\n|\r/g, '\n');
          const delimiter = normalizedContent.includes(';') ? ';' : ',';          
          
          let csvRows = normalizedContent.split('\n');
          csvRows = csvRows.filter(linha => linha.trim() !== '');

          // Mapeia cada linha para um array de células
          csvRows = csvRows.map(row => row.split(delimiter));              

          function removeHiddenCharacters(stringTitle) {
            if (!stringTitle) return '';

            return stringTitle.replace('\r', '').trim().toString();
          }

          const originalTitles = csvRows[0];
          const cleanTitles = originalTitles.map(stringTitle => removeHiddenCharacters(stringTitle));
          csvRows[0] = cleanTitles;

          // Recriar o CSV com os títulos limpos
          const cleanedCsvContent = csvRows.map(row => row.join(',')).join('\n');

          // Criar um arquivo Blob com o CSV limpo
          let cleanedCsvBlob = new Blob([cleanedCsvContent], { type: 'text/csv;charset=utf-8;' });
          resolve(cleanedCsvBlob);
        }

        reader.onerror = () => {
          this.alertMessage += "Error when reading the CSV.<br>";
          this.responseStatusCode = "pending";
          this.validateImportSuccess = false;
          reject(this.validateImportSuccess);
        };

        reader.readAsText(file);
      });
    },

    async checkFileColumnsTitles(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = async (event) => {
          const csvContent = event.target.result;
          const normalizedContent = csvContent.replace(/\r\n|\r/g, '\n');
          const delimiter = normalizedContent.includes(';') ? ';' : ',';          
          
          let csvRows = normalizedContent.split('\n');
          csvRows = csvRows.filter(linha => linha.trim() !== '');

          // Mapeia cada linha para um array de células
          csvRows = csvRows.map(row => row.split(delimiter));          
          
          await this.listFieldsByCourse();
          const compareFields = this.listFields.fields;

          function removeHiddenCharacters(stringTitle) {
            if (!stringTitle) return '';

            return stringTitle.replace(/\r/g, '').trim().toString();
          }

          if (compareFields !== null) {
            const originalTitles = csvRows[0];
            const cleanTitles = originalTitles.map(stringTitle => removeHiddenCharacters(stringTitle));
            const setCSVHeaders = new Set(cleanTitles);
            
            const findedFields = compareFields.filter(field => setCSVHeaders.has(field.name));

            if (compareFields.length !== findedFields.length) {
              const countDiffFields = compareFields.length - findedFields.length;              
              const diffFields = compareFields.filter(field => !setCSVHeaders.has(field.name));
              this.alertMessage += `The CSV file has ${countDiffFields} different column titles than the required ones.<br>`;
              this.responseStatusCode = "pending";
              this.validateImportSuccess = false;

              if (Array.isArray(diffFields)) {
                var textDiffFields = diffFields.map(field => field.name).join(', ');
              } else {
                var textDiffFields = diffFields;
              }

              this.logMessage += `CSV TITLES ERROR:\nThe CSV file has ${countDiffFields} different column titles than the required ones.\nThe columns with different titles are: ${textDiffFields}.\n\n`;
            } else {
              this.validateImportSuccess = true;
            }
          } else {
            this.alertMessage += "Error on list the required fields for CSV.<br>";
            this.responseStatusCode = "pending";
            this.validateImportSuccess = false;
          }
          resolve(this.validateImportSuccess);
        };

        reader.onerror = () => {
          this.alertMessage += "Error when reading the CSV.<br>";
          this.responseStatusCode = "pending";
          this.validateImportSuccess = false;
          reject(this.validateImportSuccess);
        };

        reader.readAsText(file);
      });
    },

    async checkFilePhoneNumbers(file) {
      try {
        // Função para ler o arquivo e retornar seu conteúdo como texto
        const readFileAsText = (file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => resolve(event.target.result);
            reader.onerror = (error) => reject(error);
            reader.readAsText(file);
          });
        };

        // Ler o conteúdo do arquivo
        const csvContent = await readFileAsText(file);
        // Normaliza as quebras de linha para '\n'
        const normalizedContent = csvContent.replace(/\r\n|\r/g, '\n');
        const delimiter = normalizedContent.includes(';') ? ';' : ',';          
        
        let csvRows = normalizedContent.split('\n');
        csvRows = csvRows.filter(linha => linha.trim() !== '');

        // Mapeia cada linha para um array de células
        csvRows = csvRows.map(row => row.split(delimiter));
        csvRows.shift(); // Remove a linha com os títulos das colunas

        // DDI selecionado e número de caracteres esperado
        const compareDDI = this.selectedDDI.replace('+', '');
        const ddiCharLength = {
          '1': 11,   // EUA
          '51': 11,  // Peru
          '52': 13,  // México
          '54': 13,  // Argentina
          '55': 13,  // Brasil
          '56': 11,  // Chile
          '57': 12,  // Colômbia
          '598': 11, // Uruguai
          '593': 11  // Equador
        };
        const numeroCaracteres = ddiCharLength[compareDDI] || 13; // Padrão: 13

        // Identificar o índice da coluna que contém os números de telefone
        const phoneColumnIndex = await this.identifyColumnPhone();

        if (phoneColumnIndex === -1) {
          this.alertMessage += "Phone column not found in CSV.<br>";
          this.responseStatusCode = "pending";
          this.validateImportSuccess = false;
          this.logMessage += "CHECK PHONE ERROR:\nPhone column not found in CSV.\n\n";
          return false;
        }

        // Extrair os números de telefone
        const phoneNumbers = csvRows.map(row => (row[phoneColumnIndex] || '').trim().replace('+', ''));

        // Identificar números com DDI correto e comprimento esperado
        const filteredNumbers = phoneNumbers.filter(number =>
          number.startsWith(compareDDI) && number.length === numeroCaracteres
        );

        // Verificar números inválidos
        if (phoneNumbers.length > filteredNumbers.length) {
          const diffNumbers = phoneNumbers.length - filteredNumbers.length;
          const nonstandardNumbers = phoneNumbers.filter(number =>
            !number.startsWith(compareDDI) || number.length !== numeroCaracteres
          );

          this.alertMessage += `${diffNumbers} non-standard (wrong) telephone numbers were found.<br>`;
          this.responseStatusCode = "pending";
          this.validateImportSuccess = false;

          const textNonStandardNumbers = nonstandardNumbers.join(', ');

          this.logMessage += `CHECK PHONE ERROR:\n${diffNumbers} non-standard (wrong) telephone numbers were found.\nThe non-standard numbers are: ${textNonStandardNumbers}\n\n`;
          return false;
        }

        // Validação bem-sucedida
        this.validateImportSuccess = true;
        return true;

      } catch (error) {
        // Tratamento de erro na leitura do arquivo
        this.alertMessage += "Error when reading the CSV.<br>";
        this.responseStatusCode = "pending";
        this.validateImportSuccess = false;
        this.logMessage += `CHECK PHONE ERROR:\nAn error occurred: ${error.message}\n\n`;
        return false;
      }
    },

    // Função para identificar a coluna de telefone
    identifyColumnPhone() {
      this.listFieldsByCourse();

      if (Array.isArray(this.listFields.fields)) {
        const phoneIndex = this.listFields.fields.findIndex(field => field.category === 'phone');

        return phoneIndex;
      } else {
        return -1;
      }
    },

    checkCSVRowsDatas(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
          const csvContent = event.target.result;
          const normalizedContent = csvContent.replace(/\r\n|\r/g, '\n');
          const delimiter = normalizedContent.includes(';') ? ';' : ','; 
          
          // Divide o conteúdo em linhas
          let csvRows = normalizedContent.split('\n');
          csvRows = csvRows.filter(linha => linha.trim() !== '');

          // Mapeia cada linha para um array de células
          csvRows = csvRows.map(row => row.split(delimiter));

          // Identifica as linhas com células vazias
          const emptyRows = csvRows
            .map((row, index) => ({
              index: index + 1, // Ajusta o índice para corresponder às linhas do CSV
              isEmpty: row.some(cell => cell.trim() === '')
            }))
            .filter(row => row.isEmpty)
            .map(row => row.index);

          if (emptyRows.length === 0) {
            this.validateImportSuccess = true;
            resolve(true);
          } else {
            this.alertMessage += `O arquivo CSV contém dados vazios nas células das linhas: ${emptyRows.join(', ')}.<br>`;
            this.responseStatusCode = "pending";
            this.validateImportSuccess = false;

            this.logMessage += `ERRO DE DADOS CSV:\nO arquivo CSV contém dados vazios nas células das linhas: ${emptyRows.join(', ')}.\n\n`;
            resolve(false);
          }
        };

        reader.onerror = () => {
          this.alertMessage += "Erro ao ler o CSV.<br>";
          this.responseStatusCode = "pending";
          this.validateImportSuccess = false;
          reject(new Error("Erro ao ler o CSV."));
        };

        reader.readAsText(file);
      });
    },

    createPushBatch(formData) {
      this.isImporting = true
      this.showAlert = true
      this.alertMessage = "Importing the Data. Please wait!"
      this.responseStatusCode = "pending"      

      http_file.post('/api/v1/dashboard/create-push-batch/', formData)
      .then((response) => {
        this.showAlert = true
        this.alertMessage = response.data.detail
        this.responseStatusCode = "success"
        this.pushBatchIdentifier = ""
        this.pushBatchFile = ""
        document.getElementById('identifier').value = ""
        document.getElementById('file').value = ""
      })
      .catch((error) => {
        this.showAlert = true
        this.alertMessage = error.response.data.detail
        this.responseStatusCode = "error"
        this.pushBatchFile = ""
        document.getElementById('file').value = ""
      })
      .finally(() => {
        this.isImporting = false
        this.getAllPushBatches()
      })
    },

    dismissPushBatchAlert() {
      this.showPushBatchAlert = false;
    },

    cancelPushBatch() {
      // this.showPushBatchForm = false
      this.showAlert = false
      this.alertMessage = ""
      this.pushBatchIdentifier = ""
      this.pushBatchFile = ""
      this.showPushBatchAlert = true;
      document.getElementById('identifier').value = ""
      document.getElementById('file').value = ""
    },

    getAllPushBatches() {
      let payload = {
          token: this.token_user,
          course_id: this.project_id
      }

      http.post('/api/v1/dashboard/list-push-batch-by-user/', payload)
      .then((response) => {
          this.pushBatches = response.data;
      })
      .catch((error) => {
      })
      .finally(() => {
          this.loading = false
      })
    },

    async CSVExampleDownload() {      
      this.showAlert = true
      this.alertMessage = "Downloading the example CSV file. Please wait!"
      this.responseStatusCode = "pending"
      
      if (this.validateImportSuccess === true) {
        this.downloadCSV(this.listFields.fields);
      } else {
        this.showAlert = true
        this.alertMessage = "Error on downloading the example CSV file. Please try again later!"
        this.responseStatusCode = "error"
      }
    },

    async listFieldsByCourse() {
      let payload = {
          course_id: this.project_id
      }

      await http.post('/api/v1/dashboard/list-fields-by-course/', payload)
      .then((response) => {
        this.listFields = response.data;
      })
      .catch((error) => {
        this.validateImportSuccess = false;
        this.showAlert = true
        this.alertMessage = "An error occurred while listing fields for CSV file. Please try again."
        this.responseStatusCode = "error"
      })
      .finally(() => {
        this.validateImportSuccess = true;
      })
    },

    downloadCSV(fields) {
      let exampleTitles = []
      let exampleRow = []

      fields.forEach(field => {
        exampleTitles.push(field.name.toString())
        exampleRow.push(field.name.toString() + ' 01')
      })

      const allRows = [exampleTitles, exampleRow]
      const csvContent = "data:text/csv;charset=utf-8," + allRows.map(row => row.join(",")).join("\n");
      let encodedUri = encodeURI(csvContent)
      let link = document.createElement("a")

      link.setAttribute("href", encodedUri)
      link.setAttribute("download", "example.csv")
      document.body.appendChild(link)
      link.click()

      this.showAlert = true
      this.alertMessage = "The example CSV file has been downloaded."
      this.responseStatusCode = "success"
    },

    downloadLog() {
      // Conteúdo do arquivo de texto
      const fileContent = this.logMessage;
      // Nome do arquivo
      const fileName = "errorDetails.txt";
      
      // Criação de um Blob com o conteúdo
      const blob = new Blob([fileContent], { type: "text/plain" });
      // Criação de uma URL para o Blob
      const url = URL.createObjectURL(blob);
      
      // Criação de um elemento <a> para o download
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      
      // Limpeza: remover o link temporário e liberar a URL
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    },
  }
}
</script>

<style scoped>
  .form-control[type=file]:not(:disabled):not([readonly]) {
    background-color: white;
    border: 1px solid #d2d6da;
    color: black;
  }

  .text-alert{
    color: #FFF;
  }

  .push-batch-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    gap: 15px;
  }
</style>