<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2 pb-4">
            <div class="row bg-gradient-info shadow-black border-radius-lg pt-4 pb-3 mb-3">
              <h6 class="col text-white text-capitalize ps-3">Depositions</h6>
            </div>

            <Loading message="Loading your reports" :loading="loading" />
            <Loading message="Loading next page reports" :loading="loading_incrementpage" />
              <Loading message="Loading previous page reports" :loading="loading_decrementpage" />

            <!-- Cards contendo icones de estrela com a mesma quantidade do score e o contador em cada card -->
            <div v-if="returnAPI" class="col">
              <div v-if="returnAPI.total_depoinments" class="row">
                <div class="col-lg-4 col-md-12 col-sm-12 mt-3">
                  <mini-statistics-card
                    :title="{ text: 'Total Depositions', value: returnAPI.total_depoinments ?? 0 }"
                    :icon="{
                      name: 'star',
                      color: 'text-white',
                      background: 'dark',
                    }"
                  />
                </div>

                <div v-if="returnAPI.resume_score" class="col-lg-4 col-md-12 col-sm-12 mt-3" v-for="score in [1, 2, 3, 4, 5]" :key="score">
                  <mini-statistics-card
                    :title="{ text: '⭐'.repeat(score), value: returnAPI.resume_score[score] ?? 0 }"
                    :icon="{
                      name: 'star',
                      color: 'text-white',
                      background: score >= 4 ? 'success' : score >= 3 ? 'info' : 'danger',
                    }"
                  />
                </div>
              </div>
            </div>

            <div v-if="returnAPI" id="report">
              <!-- Select com listagem de cursos -->
              <div class="col-lg-12 col-md-12 col-sm-12 mt-3 mb-3 d-flex justify-content-between align-items-center">
                <div class="col-6 d-flex justify-content-start">
                  <select @change="searchDepositions" v-model="course_id" class="form-select form-select-lg" aria-label="Default select example">
                    <option :value="null" selected>Choose a course</option>
                    <option 
                      v-for="course in returnAPI.all_courses.sort((a, b) => {
                        if (a.id > b.id) {
                          return 1;
                        }

                        if (a.id < b.id) {
                          return -1;
                        }

                        return 0;
                      })" :key="course.id" :value="course.id">
                      #{{ course.id }} - {{ course.name }}
                    </option>
                  </select>
                </div>

                <div class="col-6 d-flex justify-content-end">
                  <button @click="downloadReport" class="btn bg-gradient-secondary fs-5">
                    <i class="bi bi-cloud-download"></i>
                  </button>
                </div>
              </div>

              <div id="total" role="alert" class="col ps-3 w-25 alert alert-danger" v-if="Array.isArray(returnAPI.data) && returnAPI.data.length <= 0 && showMessage === true && !loading">
                There are no Depositions.
              </div>

              <Loading message="Download on progress" :loading="loading_download" />
                            
              <ReportTable v-if="!loading && !loading_download && !loading_incrementpage && !loading_decrementpage" :returnAPI="returnAPI.data" />
              <nav v-if="!loading && !loading_download" aria-label="Page navigation example">
                <ul class="pagination justify-content-center gap-3 align-items-center">
                  <li v-if="pagination > 1" class="page-item">
                    <a @click="decrementPage" class="page-link"><i class="bi bi-chevron-double-left"></i></a>
                  </li>

                  <li>
                    {{ pagination }} of {{ total_pages }}
                  </li>

                  <li v-if="pagination < total_pages" class="page-item">
                    <a @click="incrementPage" class="page-link"><i class="bi bi-chevron-double-right"></i></a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// deploy
import ReportTable from "@/views/components/DepositionsTable.vue";
import Loading from "@/views/components/Loading.vue";
import MiniStatisticsCard from "./components/MiniStatisticsCard.vue";
import { http } from "../http/index";

export default {
  name: "Depositions",
  components: {
    ReportTable,
    Loading,
    MiniStatisticsCard,
  },
  data() {
    return {
      token: localStorage.getItem("token"),
      returnAPI: null,
      course_id: null,
      loading: true,
      loading_download: false,
      loading_incrementpage: false,
      loading_decrementpage: false,
      showMessage: false,
      pagination: 1,
      total_pages: 1,
      returnDownloadCSV: null,
    };
  },
  async mounted() {
    await this.searchDepositions();
  },
  methods: {
    incrementPage() {
      this.loading_incrementpage = true;
      this.returnAPI = null;
      this.pagination += 1;
      this.searchDepositions();
    },

    decrementPage() {
      this.loading_decrementpage = true;
      this.returnAPI = null;
      this.pagination -= 1;
      this.searchDepositions();
    },

    async downloadReport() {
      this.loading_download = true;
      const payload = {
        token_user: this.token,
        pagination: 1,
        ...(this.course_id && { course_id: this.course_id }),
      };

      var compound_name = '';
      if (this.course_id !== null) {
        compound_name = `course-${this.course_id}`;
      } else {
        compound_name = 'company-all-courses';
      }

      try {
        const response = await http.post("/api/v1/dashboard/download-depositions/", payload);
        const data = response.data;
        const blob = new Blob([data], { type: "application/csv" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `depositions-${compound_name}.csv`;
        link.click();
      } catch (error) {
        console.error(error);
      } finally {
        this.loading_download = false;
      }
    },

    async searchDepositions() {
      this.returnAPI = null;

      if (!this.loading_incrementpage && !this.loading_decrementpage && !this.loading_download) {
        this.loading = true;
      }

      const payload = {
        token_user: this.token,
        pagination: this.pagination,
        ...(this.course_id && { course_id: this.course_id }),
      };

      var mountedIndicatorData = [['Score', 'Total Answers']];

      await http.post("/api/v1/dashboard/get-depositions/", payload)
      .then((response) => {
        this.returnAPI = response.data;
        this.total_pages = response.data.total_pages;
      })
      .catch((error) => {
        console.error(error);
        this.returnAPI = null;
        this.showMessage = true;
      })
      .finally(() => {
        this.loading = false;
        this.loading_download = false;
        this.loading_incrementpage = false;
        this.loading_decrementpage = false;
      })
    },
  },
};
</script>

<style scoped>
#total {
  font-size: 16px;
  color: white;
  text-align: center;
}

#loading {
  padding-bottom: 15px;
  text-align: center;
  vertical-align: middle;
}

#not_found {
  color: white;
  text-align: center;
}

#report {
  padding-top: 5px;
  font-size: 16px;
  color: #424242;
}
</style>